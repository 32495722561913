export default {
  API_URL: "https://api-cygnet-west-prod.bookacorner.net",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.bookacorner.net",
  CLIENT: "cygnet-west",
  CLIENT_NAME: "Pop Up Place",
  GOOGLE_MAPS_API_KEY: "AIzaSyBZ_m4Ev975xE29-Q7BQbjQpVpchNhxNO4",
  HOME_URL: "https://popupplace.com.au",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.bookacorner.net/all/media/Cygnet-West/Pop Up Place Banner.jpg",
  HOME_COMMENT_TEXT: "THE IMPLEMENTATION OF STAGING HAS BEEN A REVOLUTION RETAILERS AND OWNERS IN COMMERCIAL REAL ESTATE",
  HOME_COMMENT_AUTHOR: "Mr Staging",
  HOME_COMMENT_LINK: "https://bookacorner.com",
  HOME_COMMENT_IMAGE: "https://files.bookacorner.net/all/media/BAC/foto_branding.png",
  LOGO_LIGHT: "https://files.bookacorner.net/all/media/Pop Up Place/Pop Up Place_Logo_Light.png",
  LOGO_DARK: "https://files.bookacorner.net/all/media/Pop Up Place/Pop Up Place_Logo_Dark.jpg",
  ADMIN_DEFAULT_LOCALE: "en-cbreau",
  TENANT_DEFAULT_LOCALE: "en-cbreau",
  ABOUT_US: "",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/Pop Up Place Privacy Policy.pdf",
  ABOUT_US_PDF: "https://files.bookacorner.net/all/media/Pop Up Place/Pop Up Place_About_Us.pdf",
  SOCIAL_LINKS: {"facebook":"https://www.facebook.com/profile.php?id=61560552692139","instagram":"https://www.instagram.com/pop.up.place/"},
  ADVERTISING_IMG: "https://files.bookacorner.net/all/media/912de3e4-b8bc-4a9e-bbf4-f3c0d5ffb6ef.jpg",
  REVERB_APP_KEY: "xZ8AatlKhwZmH2uRC0QZgOkJ",
  REVERB_HOST: "api-cygnet-west-prod.bookacorner.net",
  REVERB_AUTH_ENDPOINT: "https://api-cygnet-west-prod.bookacorner.net/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&family=Raleway:wght@400;500;600;700&display=swap",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","it","es-cbreau","en-cbreau","pt-cbreau","fr-cbreau","it-cbreau"],
  MAPBOX_API_KEY: "pk.eyJ1IjoiYWxleGlzcGFndXJhIiwiYSI6ImNscDBnM2lvaTA4ZzEyaW1kcG83MHV5OTIifQ.oOPsNiopJzxHHDUyERjMgw",
  HOME_MAP_STYLE: "mapbox://styles/alexispagura/clw5myv1x095t01ph1hnldpia",
  HOME_MAP_BOUNDS: [[112.62118121812236,-36.54617577107478],[129.2439249473218,-12.191036709104273]],
  MAPBOX_MARKER: "https://files.bookacorner.net/all/markers/mapbox-icon-CBRE.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "",
  CONTACT_US: {"email":"info@popupplace.com.au","phone":"Direct: +61 8 6375 6056 | Mobile: +61 434 659 904"},
  COGNITO_URL: "https://cygnet-west.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=6lj2mql92t7i3k7ipkj7np1p3c&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile",
}
