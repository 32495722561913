/* eslint-disable react/destructuring-assignment */
import {
  CHANGE_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  GET_BOOKING,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  GET_BOOKING_FEED,
  GET_BOOKING_FEED_SUCCESS,
  GET_BOOKING_FEED_ERROR,
  RESET_CONSULTATION,
  SEND_BOOKING_REQUEST,
  SEND_BOOKING_REQUEST_SUCCESS,
  SEND_BOOKING_REQUEST_ERROR,
  ACCEPT_BOOKING,
  REJECT_BOOKING,
  RESET_PROCESSING_BOOKING_ACTION,
  ACCEPT_BOOKING_SUCCESS,
  REJECT_BOOKING_SUCCESS,
  ACCEPT_BOOKING_ERROR,
  REJECT_BOOKING_ERROR,
  SIGN_BOOKING,
  SIGN_BOOKING_SUCCESS,
  SIGN_BOOKING_ERROR,
  SIGN_BOOKING_INIT,
  PAY_BOOKING,
  PAY_BOOKING_SUCCESS,
  PAY_BOOKING_ERROR,
  PAY_BOOKING_INIT,
  GET_BOOKING_OVERLAP_SUCCESS,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERROR,
  SET_BOOKING_PRICE,
  SET_BOOKING_PAYMENT_PLAN,
  ATTACH_FILE_TO_BOOKING,
  ATTACH_FILE_TO_BOOKING_SUCCESS,
  ATTACH_FILE_TO_BOOKING_ERROR,
  DETACH_FILE_TO_BOOKING,
  DETACH_FILE_TO_BOOKING_SUCCESS,
  DETACH_FILE_TO_BOOKING_ERROR,
  GET_BOOKING_RATING,
  GET_BOOKING_RATING_SUCCESS,
  GET_BOOKING_RATING_ERROR,
  FOLLOW_BOOKING,
  FOLLOW_BOOKING_SUCCESS,
  FOLLOW_BOOKING_ERROR,
  SET_BOOKING_AD_SPACES,
  SET_BOOKING_AD_SPACES_SUCCESS,
  SET_BOOKING_AD_SPACES_ERROR,
  RESET_BOOKING_AD_SPACES_STATUS,
  GET_BOOKING_INVOICES,
  GET_BOOKING_INVOICES_SUCCESS,
  GET_BOOKING_INVOICES_ERROR,
  UPDATE_BOOKING_INVOICE,
  GET_BOOKING_UNAVAILABLE_DATE_RANGES,
  GET_BOOKING_UNAVAILABLE_DATE_RANGES_SUCCESS,
  GET_BOOKING_UNAVAILABLE_DATE_RANGES_ERROR,
  UPDATE_EVENT,
  MODIFY_LOCAL_CONTRACT_DATA,
  UPDATE_CONTRACT_DATA_RESET,
  UPDATE_CONTRACT_DATA,
  UPDATE_CONTRACT_DATA_ERROR,
  UPDATE_CONTRACT_DATA_SUCCESS,
  SET_BOOKING_CONTRACT_METADATA,
  SET_BOOKING_SPACE_COMMERCIAL_ACTIVITY,
  GET_BOOKING_CONTRACT,
  GET_BOOKING_CONTRACT_ERROR,
  GET_BOOKING_CONTRACT_SUCCESS,
  SEND_BOOKING_CONTRACT_TO_DOCUSIGN,
  SEND_BOOKING_CONTRACT_TO_DOCUSIGN_ERROR,
  SEND_BOOKING_CONTRACT_TO_DOCUSIGN_SUCCESS,
  RESET_BOOKING_CONTRACT_TO_DOCUSIGN_STATUS,
  UPDATE_BOOKING_CONTRACT,
  UPDATE_BOOKING_CONTRACT_ERROR,
  UPDATE_BOOKING_CONTRACT_SUCCESS,
  REMOVE_BOOKING_CONTRACT_CONTENT,
  GET_BOOKING_CHAIN,
  GET_BOOKING_CHAIN_SUCCESS,
  GET_BOOKING_CHAIN_ERROR,
  SET_BOOKING_HAS_SET_CATEGORIES,
  SET_INCOME_BOOKING_CATEGORY,
  REMOVE_BOOKING_GENERATED_CONTRACT,
  SET_TOGGLE_PAYMENT_MODAL,
  PAY_INVOICE,
  PAY_INVOICE_SUCCESS,
  PAY_INVOICE_ERROR,
  PAY_INVOICE_INIT,
  UPDATE_BOOKING_PARAMS,
  DELETE_BOOKING_INVOICE,
} from "../actions/consultation-actions";

import { SHOW_CARD } from "../actions/space-card-actions";
import { alphabetList, extrapolateContractModel, userFormatter } from "../../utils";
import { formatUTCDateNow, add, dateDiff } from "../../utils/dateUtils";
import { transformSpace } from "./space-reducer";

import { CONSULT_STATUS } from ".";

export const INITIAL_STATE = {
  status: "initial",
  sendStatus: "initial",
  messagesStatus: "initial",
  bookingChainStatus: "initial",
  bookingChain: [],
  data: {
    listing: {
      id: "loading",
      asset: {
        name: "",
        city: {
          name: "",
        },
      },
    },
    asset: {},
    contractModel: {
      adminTemplateFields: [],
      customerTemplateFields: [],
    },
    contractCallback: {},
    projectDetails: "",
    projectName: "",
    projectType: "",
    startDate: new Date(),
    endDate: new Date(),
    participants: [],
    state: "",
    bookingId: "",
    use: {},
    followers: [],
    adSpaces: [],
    price: 0,
    paymentPlan: "",
    adSpacesUses: [],
    adSpacesWithUses: {},
    confirmedAt: "",
  },
  isFollower: false,
  rating: {
    avg: 0,
  },
  ratingsStatus: "initial",
  messages: [],
  currentMessage: "",
  overlaps: [],
  invoices: [],
  contract: null,
  unavailableDatesRange: [],
  unavailableDateRangesStatus: "initial",
  sendBookingStatus: "initial",
  processingBookingStatus: "initial",
  cancelBookingStatus: "initial",
  signStatus: "initial",
  payStatus: "initial",
  errorMessage: "",
  attachingStatus: "initial",
  detachingStatus: "initial",
  setBookingAdSpacesStatus: "initial",
  invoiceStatus: "initial",
  getBookingContractStatus: "initial",
  sendBookingContractToDocusignStatus: "initial",
  updateBookingContractStatus: "initial",
  removeBookingContractStatus: "initial",
  payInvoiceStatus: "initial",
};

export const getBookingSignedContract = (contract) => {
  return contract && contract?.files_metadata?.signeduploadfile
    ? decodeURI(contract?.files_metadata?.signeduploadfile?.url)
    : contract?.files_metadata?.tenantsigned
    ? decodeURI(contract?.files_metadata?.tenantsigned?.url)
    : "#";
};

export const transformBooking = (data) => {
  const {
    id,
    agent = {},
    agent_id,
    author,
    spaces,
    // TODO: deprecate
    contract_data,
    contract_callback,
    has_contract,
    contractmodel,
    attachments,
    project_details,
    project_name,
    brand_name,
    payment_plan,
    booking_category_id,
    category_income,
    space_activity_coefficient_id,
    asset,
    status,
    price,
    start,
    end,
    long_term,
    followers,
    adspaces,
    days_count,
    periodPrice,
    show_prices_info,
    params,
    parent_id,
    confirm_on,
    confirmed_at,
  } = data;
  const startDate = start; // these dates come without tz already formatted. ie: 2021-04-02
  const endDate = end;
  const fprice = parseFloat(price);
  const objParticipants = followers
    ? followers.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: userFormatter(curr),
        }),
        {},
      )
    : {};

  const space = spaces?.length ? spaces[0] : {};
  return {
    bookingId: parseInt(id),
    parentId: parent_id,
    asset,
    attachments,
    contractModel: extrapolateContractModel(contractmodel),
    contractCallback: contract_callback || {},
    hasContract: has_contract,
    contractData: contract_data,
    params: { ...params },
    bookingCategory: booking_category_id,
    categoryIncome: category_income,
    agent: {
      id: agent?.id,
      email: agent?.email,
      name: agent?.name,
      lastname: agent?.lastname,
    },
    agentId: agent_id,
    listing: {
      ...transformSpace({ ...space, asset }),
    },
    author,
    projectDetails: project_details,
    projectName: project_name,
    brandName: brand_name,
    paymentPlan: payment_plan,
    startDate,
    endDate,
    long_term,
    price: fprice,
    periodPrice: parseFloat(periodPrice),
    state: status, // pending, visit, canceled, etc..
    participants: objParticipants,
    followers,
    days: days_count, // getDays(startDate, endDate),
    adSpaces: adspaces,
    spaces,
    hasSetCategories: booking_category_id > 0 && booking_category_id !== null,
    spaceActivityCoefficientId: space_activity_coefficient_id,
    showPricesInfo: show_prices_info,
    confirmOn: confirm_on,
    confirmedAt: confirmed_at,
  };
};

const ConsultationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_MESSAGE:
      return {
        ...state,
        currentMessage: action.payload.content,
      };
    case SEND_MESSAGE:
      return {
        ...state,
        sendStatus: "loading",
      };
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        sendStatus: "success",
        currentMessage: action.payload.message?.avoidResetInput ? state.currentMessage : "",
        messages: [
          ...state.messages,
          {
            ...action.payload.message,
            created_at: formatUTCDateNow("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      };
    }
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendStatus: "error",
        error: action.payload.error,
      };
    case GET_BOOKING: {
      return {
        ...state,
        status: "loading",
        data: {
          ...INITIAL_STATE.data,
          bookingId: action.payload.bookingId,
        },
      };
    }
    case GET_BOOKING_SUCCESS: {
      const data = transformBooking(action.payload.data);
      // If the booking was not loading it means nobody wants it open
      // it was closed for example
      if (state.status !== "loading") {
        return {
          ...state,
        };
      }

      return {
        ...state,
        status: "success",
        data,
        // backend guat
        isFollower: action.payload.data[0] && action.payload.data[0].userIsFollower,
        processingBookingStatus: "initial",
        cancelBookingStatus: "initial",
        updateContractDataStatus: "initial",
        signStatus: "initial",
        // deprecate
        localContractData: data.contract_data || {},
        // we update the booking chain with the new booking price (if any)
        bookingChain: state.bookingChain.map((b, index) => ({
          ...b,
          end: index ? b.end : state.bookingChain[state.bookingChain.length - 1].end,
          tabName: index ? `${alphabetList[index - 1]}` : `#${b.id}`,
          tabLink: index ? `${b.id}-${alphabetList[index - 1]}` : `${b.id}`,
          price: b.id === data.bookingId ? data.price : b.price,
        })),
      };
    }
    case GET_BOOKING_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_BOOKING_OVERLAP_SUCCESS:
      return {
        ...state,
        overlaps: action.payload.overlaps,
      };
    case GET_BOOKING_FEED:
      return {
        ...state,
        messagesStatus: "loading",
        messages: [],
      };
    case GET_BOOKING_FEED_ERROR:
      return {
        ...state,
        messagesStatus: "error",
        error: action.payload.error,
      };
    case GET_BOOKING_FEED_SUCCESS: {
      const { messages } = action.payload;
      return {
        ...state,
        messagesStatus: "success",
        messages: messages.payload.data.length !== state.messages.length ? messages.payload.data : state.messages,
      };
    }
    case RESET_CONSULTATION: {
      return {
        ...state,
        messages: [],
        bookingChain: [],
        data: {
          ...INITIAL_STATE.data,
        },
        isFollower: false,
        messagesStatus: "initial",
        status: "initial",
      };
    }
    case SHOW_CARD: {
      return {
        ...state,
        sendBookingStatus: "initial",
      };
    }
    case SEND_BOOKING_REQUEST:
      return {
        ...state,
        sendBookingStatus: "loading",
      };
    case SEND_BOOKING_REQUEST_SUCCESS:
      return {
        ...state,
        sendBookingStatus: "success",
      };
    case SEND_BOOKING_REQUEST_ERROR:
      return {
        ...state,
        sendBookingStatus: "error",
      };
    case ACCEPT_BOOKING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          state: CONSULT_STATUS.ACCEPTED,
        },
        processingBookingStatus: "success",
      };
    case REJECT_BOOKING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          state: CONSULT_STATUS.PENDING,
        },
        processingBookingStatus: "success",
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBookingStatus: "success",
      };
    case ACCEPT_BOOKING_ERROR:
    case REJECT_BOOKING_ERROR:
      return {
        ...state,
        processingBookingStatus: "error",
        errorMessage: action.payload.error,
      };
    case CANCEL_BOOKING_ERROR:
      return {
        ...state,
        cancelBookingStatus: "error",
      };
    case ACCEPT_BOOKING:
    case REJECT_BOOKING:
      return {
        ...state,
        processingBookingStatus: "loading",
        processingMessageId: action.payload.messageId,
      };
    case RESET_PROCESSING_BOOKING_ACTION: {
      return {
        ...state,
        processingBookingStatus: "initial",
        unavailableDateRangesStatus: "initial",
        sendBookingStatus: "initial",
        cancelBookingStatus: "initial",
        signStatus: "initial",
        payStatus: "initial",
      };
    }
    case CANCEL_BOOKING:
      return {
        ...state,
        cancelBookingStatus: "loading",
      };
    case SIGN_BOOKING:
      return {
        ...state,
        signStatus: "loading",
      };
    case SIGN_BOOKING_SUCCESS:
      return {
        ...state,
        signStatus: "success",
      };
    case SIGN_BOOKING_ERROR:
      return {
        ...state,
        signStatus: "error",
      };
    case SIGN_BOOKING_INIT:
      return {
        ...state,
        signStatus: "initial",
      };
    case PAY_BOOKING:
      return {
        ...state,
        payStatus: "loading",
      };
    case PAY_BOOKING_SUCCESS:
      return {
        ...state,
        payStatus: "success",
        data: {
          ...state.data,
          state: "paid",
        },
      };
    case PAY_BOOKING_ERROR:
      return {
        ...state,
        payStatus: "error",
      };
    case PAY_BOOKING_INIT:
      return {
        ...state,
        payStatus: "initial",
      };
    case SET_BOOKING_PRICE:
      return {
        ...state,
        data: {
          ...state.data,
          price: action.payload.price,
        },
      };
    case SET_BOOKING_PAYMENT_PLAN:
      return {
        ...state,
        data: {
          ...state.data,
          paymentPlan: action.payload.paymentPlan,
        },
      };
    case ATTACH_FILE_TO_BOOKING:
      return {
        ...state,
        attachingStatus: "loading",
      };
    case ATTACH_FILE_TO_BOOKING_SUCCESS:
      return {
        ...state,
        attachingStatus: "success",
        data: {
          ...state.data,
          attachments: state.data.attachments.concat(action.payload.file),
        },
      };
    case ATTACH_FILE_TO_BOOKING_ERROR:
      return {
        ...state,
        attachingStatus: "error",
      };
    case DETACH_FILE_TO_BOOKING:
      return {
        ...state,
        detachingStatus: "loading",
      };
    case DETACH_FILE_TO_BOOKING_SUCCESS:
      return {
        ...state,
        detachingStatus: "success",
        data: {
          ...state.data,
          attachments: state.data.attachments.filter((a) => a.id !== action.payload.fileId),
        },
      };
    case DETACH_FILE_TO_BOOKING_ERROR:
      return {
        ...state,
        detachingStatus: "error",
      };
    case GET_BOOKING_RATING:
      return {
        ...state,
        ratingsStatus: "loading",
        rating: {
          avg: 0,
        },
      };
    case GET_BOOKING_RATING_SUCCESS: {
      const rating = action.payload.map((r) => ({
        ...r,
        avg: parseFloat(r.avg),
      }));
      return {
        ...state,
        rating: rating.length ? rating : [{ avg: 0, rating: {} }],
        ratingsStatus: "success",
      };
    }
    case GET_BOOKING_RATING_ERROR: {
      return {
        ...state,
        ratingsStatus: "error",
      };
    }
    case FOLLOW_BOOKING:
      return {
        ...state,
        followStatus: "loading",
        isFollower: !state.isFollower,
      };
    case FOLLOW_BOOKING_SUCCESS:
      return {
        ...state,
        followStatus: "success",
      };
    case FOLLOW_BOOKING_ERROR:
      return {
        ...state,
        followStatus: "error",
        isFollower: !state.isFollower,
      };
    case SET_BOOKING_AD_SPACES:
      return {
        ...state,
        setBookingAdSpacesStatus: "loading",
      };
    case SET_BOOKING_AD_SPACES_ERROR:
      return {
        ...state,
        setBookingAdSpacesStatus: "error",
        errorMessage: action.payload.error,
      };
    case SET_BOOKING_AD_SPACES_SUCCESS:
      return {
        ...state,
        setBookingAdSpacesStatus: "success",
        data: {
          ...state.data,
          adSpaces: action.payload.data,
        },
      };
    case RESET_BOOKING_AD_SPACES_STATUS:
      return {
        ...state,
        setBookingAdSpacesStatus: "initial",
      };
    case GET_BOOKING_INVOICES:
      return {
        ...state,
        invoiceStatus: "loading",
      };
    case GET_BOOKING_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceStatus: "success",
        invoices: action.payload.data,
      };
    case GET_BOOKING_INVOICES_ERROR:
      return {
        ...state,
        invoiceStatus: "error",
      };
    case UPDATE_BOOKING_INVOICE: {
      const updated = action.payload.invoice;
      return {
        ...state,
        invoices: state.invoices.map((invoice) => (invoice.id === updated.id ? updated : invoice)),
      };
    }
    case GET_BOOKING_UNAVAILABLE_DATE_RANGES: {
      return {
        ...state,
        unavailableDateRangesStatus: "loading",
      };
    }
    case GET_BOOKING_UNAVAILABLE_DATE_RANGES_SUCCESS: {
      const { data } = action.payload;
      /**
       * creates a hash with [date]: reservation to
       *  provide quick access to unavailable dates
       */
      const filteredRanges = data.filter((range) => {
        const { end } = range;
        return dateDiff(new Date(), end) >= 0;
      });
      const unavailableDates = filteredRanges.reduce((acc, curr) => {
        const { start, end } = curr;
        const diff = dateDiff(start, end);
        for (let i = 0; i <= diff; i++) {
          const date = add(start, i, "day").format("YYYY-MM-DD");
          if (acc[date] === undefined) {
            acc[date] = [curr];
          } else {
            acc[date] = [...acc[date], curr];
          }
        }
        return acc;
      }, {});

      return {
        ...state,
        unavailableDateRangesStatus: "success",
        unavailableDatesRange: unavailableDates,
      };
    }
    case GET_BOOKING_UNAVAILABLE_DATE_RANGES_ERROR: {
      return {
        ...state,
        unavailableDateRangesStatus: "error",
      };
    }
    case UPDATE_EVENT: {
      const newEvent = action.payload;
      return {
        ...state,
        messages: state.messages.map((f) => (f.event_id === newEvent.id ? { ...f, ...newEvent } : f)),
      };
    }
    case MODIFY_LOCAL_CONTRACT_DATA: {
      const localContractData = {
        ...state.localContractData,
        [action.payload.field]: action.payload.value || undefined,
      };

      return {
        ...state,
        localContractData,
        contractDataDrifted: true,
      };
    }
    case UPDATE_CONTRACT_DATA_RESET:
      return {
        ...state,
        updateContractDataStatus: "initial",
        localContractData: {
          ...(state.data.contractData || {}),
        },
        contractDataDrifted: false,
      };
    case UPDATE_CONTRACT_DATA:
      return {
        ...state,
        updateContractDataStatus: "loading",
      };
    case UPDATE_CONTRACT_DATA_ERROR:
      return {
        ...state,
        updateContractDataStatus: "error",
      };
    case UPDATE_CONTRACT_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          contractData: action.payload,
        },
        updateContractDataStatus: "success",
        contractDataDrifted: false,
        localContractData: action.payload || {},
      };
    case SET_BOOKING_CONTRACT_METADATA:
      return {
        ...state,
        contract: {
          ...state.contract,
          files_metadata: {
            ...state.contract?.files_metadata,
            [action.payload.type]: action.payload.callback,
          },
        },
      };
    case SET_BOOKING_SPACE_COMMERCIAL_ACTIVITY:
      return {
        ...state,
        data: {
          ...state.data,
          spaceActivityCoefficientId: action.payload,
        },
      };
    case GET_BOOKING_CONTRACT:
      return {
        ...state,
        getBookingContractStatus: "loading",
      };
    case GET_BOOKING_CONTRACT_ERROR:
      return {
        ...state,
        getBookingContractStatus: "error",
      };
    case GET_BOOKING_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: action.payload,
        getBookingContractStatus: "success",
      };
    case SEND_BOOKING_CONTRACT_TO_DOCUSIGN:
      return {
        ...state,
        sendBookingContractToDocusignStatus: "loading",
      };
    case SEND_BOOKING_CONTRACT_TO_DOCUSIGN_ERROR:
      return {
        ...state,
        sendBookingContractToDocusignStatus: "error",
      };
    case SEND_BOOKING_CONTRACT_TO_DOCUSIGN_SUCCESS:
      return {
        ...state,
        sendBookingContractToDocusignStatus: "success",
      };
      case RESET_BOOKING_CONTRACT_TO_DOCUSIGN_STATUS:
        return {
          ...state,
          sendBookingContractToDocusignStatus: "initial",
        };
    case UPDATE_BOOKING_CONTRACT:
      return {
        ...state,
        updateBookingContractStatus: "loading",
      };
    case UPDATE_BOOKING_CONTRACT_ERROR:
      return {
        ...state,
        updateBookingContractStatus: "error",
      };
    case UPDATE_BOOKING_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: action.payload,
        updateBookingContractStatus: "success",
      };
    case REMOVE_BOOKING_CONTRACT_CONTENT:
      return {
        ...state,
        contract: {
          ...state.contract,
          content: null,
        },
      };
    case GET_BOOKING_CHAIN:
      return {
        ...state,
        bookingChainStatus: "loading",
      };
    case GET_BOOKING_CHAIN_SUCCESS:
      return {
        ...state,
        bookingChainStatus: "success",
        bookingChain: action.payload.map((b, index) => ({
          ...b,
          end: index ? b.end : action.payload[action.payload.length - 1].end,
          tabName: index ? `${alphabetList[index - 1]}` : `#${b.id}`,
          tabLink: index ? `${b.id}-${alphabetList[index - 1]}` : `${b.id}`,
        })),
      };
    case GET_BOOKING_CHAIN_ERROR:
      return {
        ...state,
        bookingChainStatus: "error",
      };
    case SET_BOOKING_HAS_SET_CATEGORIES:
      return {
        ...state,
        data: {
          ...state.data,
          hasSetCategories: true,
          bookingCategory: action.payload,
        },
      };
    case SET_INCOME_BOOKING_CATEGORY:
      return {
        ...state,
        data: {
          ...state.data,
          categoryIncome: action.payload,
        },
      };
    case REMOVE_BOOKING_GENERATED_CONTRACT:
      return {
        ...state,
        removeBookingContractStatus: "success",
        contract: {
          ...state.contract,
          files_metadata: {
            ...state.contract.files_metadata,
            generated: null,
          },
        },
      };
    case PAY_INVOICE:
      return {
        ...state,
        payInvoiceStatus: "loading",
      };
    case PAY_INVOICE_SUCCESS:
      return {
        ...state,
        payInvoiceStatus: "success",
        data: {
          ...state.data,
          state: "paid",
        },
      };
    case PAY_INVOICE_ERROR:
      return {
        ...state,
        payInvoiceStatus: "error",
      };
    case PAY_INVOICE_INIT:
      return {
        ...state,
        payInvoiceStatus: "initial",
      };
    case UPDATE_BOOKING_PARAMS: {
      const { params } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          params,
        },
      };
    }
    case DELETE_BOOKING_INVOICE: {
      const { id } = action.payload;
      const filterInvoice = state.invoices.filter((invoice) => invoice.id !== id);
      return {
        ...state,
        invoices: filterInvoice,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default ConsultationReducer;
